<template>
  <div class="new-booking-page">
    <m-page-title title="New Booking" address />

    <div class="content-box">
      <div
        class="fn-item g-hover-pointer"
        v-for="(item, index) in fnArr"
        :key="index"
        @click="fnClick(item)"
      >
        <img :src="item.img" alt="" />
        <span>{{ item.label }}</span>
      </div>
    </div>
    <el-button class="btn" type="primary" @click="$router.push('/')">
      Back to dashboard
    </el-button>
  </div>
</template>

<script>
const fnArr = [
  {
    label: "Move In",
    type: 0,
    img: require("@/static/svg/newBooking/MoveIn.svg"),
    page: "/dateAndTime",
  },
  {
    label: "Move Out",
    type: 1,
    img: require("@/static/svg/newBooking/MoveOut.svg"),
    page: "/dateAndTime",
  },
  {
    label: "Communal Facility",
    img: require("@/static/svg/newBooking/CommunalFacility.svg"),
    type: 2,
    page: "/newBookingCommunalFacility",
  },
];
import { mapMutations, mapState } from "vuex";
export default {
  name: "NewBooking",
  data() {
    return { fnArr };
  },
  computed: {
    ...mapState("booking", ["bookdata"]),
  },
  methods: {
    ...mapMutations("booking", ["setType"]),
    fnClick(e) {
      //跳转
      this.setType(e.type);
      e.page && this.$router.push(e.page);
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
